import { createTheme, CustomTheme } from '@ibe/components';
import { CSSObjectWithLabel, StylesConfig as ReactSelectStyles } from 'react-select';

// TODO simplify by merging styles
import styles from './Styles/Bootstrap.scss';
import './Styles/IBE.scss';

const reactSelectStyles: ReactSelectStyles = {
  singleValue: undefined,
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state.isSelected ? styles.white : styles.inputColor,
      backgroundColor: state.isDisabled
        ? styles.gray400
        : state.isSelected
        ? styles.secondary
        : 'none',
      '&:hover': {
        backgroundColor: state.isDisabled
          ? styles.gray400
          : state.isSelected
          ? styles.secondary
          : styles.secondary,
        color: styles.white
      }
    } as CSSObjectWithLabel),
  indicatorsContainer: provided =>
    ({
      ...provided,
      height: '32px'
    } as CSSObjectWithLabel),
  indicatorSeparator: provided =>
    ({
      ...provided,
      display: 'none'
    } as CSSObjectWithLabel),
  dropdownIndicator: provided =>
    ({
      ...provided,
      color: styles.secondary
    } as CSSObjectWithLabel),
  control: (provided, state) =>
    ({
      ...provided,
      borderWidth: '0 0 2px',
      borderRadius: 0,
      minHeight: 'auto',
      boxShadow: 'none',
      borderColor: styles.inputBorderColor,
      '&:hover': {
        borderColor: styles.secondary
      }
    } as CSSObjectWithLabel),
  menu: provided =>
    ({
      ...provided,
      zIndex: 6
    } as CSSObjectWithLabel),
  container: undefined,
  placeholder: undefined
};

const customTheme: CustomTheme = {
  bootstrap: styles,
  searchCriteriaDisplay: {
    color: styles.secondary,
    backgroundColor: styles.white
  },
  useCalenderIcon: false,
  reactSelectStyles,
  paxSelectionOptions: {
    withIcon: false,
    shortTitle: false,
    longTitle: true
  },
  dateRangePickerOptions: {
    defaultDaysAddedToStart: 14,
    defaultDaysAddedToEnd: 28
  }
};

const theme = createTheme(customTheme);

export default theme;
