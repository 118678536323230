/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCountry,
    ApiCountryFromJSON,
    ApiCountryFromJSONTyped,
    ApiCountryToJSON,
    ApiImage,
    ApiImageFromJSON,
    ApiImageFromJSONTyped,
    ApiImageToJSON,
    ApiPrice,
    ApiPriceFromJSON,
    ApiPriceFromJSONTyped,
    ApiPriceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiProductCard
 */
export interface ApiProductCard {
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    name: string;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiProductCard
     */
    image: ApiImage;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiProductCard
     */
    smallGroupImage: ApiImage;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    dateInfo: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    partnerText: string;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiProductCard
     */
    partnerImage: ApiImage;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiProductCard
     */
    markerImage: ApiImage;
    /**
     * 
     * @type {ApiPrice}
     * @memberof ApiProductCard
     */
    price: ApiPrice;
    /**
     * 
     * @type {ApiPrice}
     * @memberof ApiProductCard
     */
    strikeThroughPrice: ApiPrice;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    context: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    detailUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    seasonName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiProductCard
     */
    productTypes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    selectedPromotionCode: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    selectedPromotionValue: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductCard
     */
    code: string;
    /**
     * 
     * @type {ApiCountry}
     * @memberof ApiProductCard
     */
    country: ApiCountry;
}

export function ApiProductCardFromJSON(json: any): ApiProductCard {
    return ApiProductCardFromJSONTyped(json, false);
}

export function ApiProductCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'image': ApiImageFromJSON(json['image']),
        'smallGroupImage': ApiImageFromJSON(json['smallGroupImage']),
        'description': json['description'],
        'dateInfo': json['dateInfo'],
        'partnerText': json['partnerText'],
        'partnerImage': ApiImageFromJSON(json['partnerImage']),
        'markerImage': ApiImageFromJSON(json['markerImage']),
        'price': ApiPriceFromJSON(json['price']),
        'strikeThroughPrice': ApiPriceFromJSON(json['strikeThroughPrice']),
        'context': json['context'],
        'detailUrl': json['detailUrl'],
        'seasonName': json['seasonName'],
        'productTypes': json['productTypes'],
        'selectedPromotionCode': json['selectedPromotionCode'],
        'selectedPromotionValue': json['selectedPromotionValue'],
        'code': json['code'],
        'country': ApiCountryFromJSON(json['country']),
    };
}

export function ApiProductCardToJSON(value?: ApiProductCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'image': ApiImageToJSON(value.image),
        'smallGroupImage': ApiImageToJSON(value.smallGroupImage),
        'description': value.description,
        'dateInfo': value.dateInfo,
        'partnerText': value.partnerText,
        'partnerImage': ApiImageToJSON(value.partnerImage),
        'markerImage': ApiImageToJSON(value.markerImage),
        'price': ApiPriceToJSON(value.price),
        'strikeThroughPrice': ApiPriceToJSON(value.strikeThroughPrice),
        'context': value.context,
        'detailUrl': value.detailUrl,
        'seasonName': value.seasonName,
        'productTypes': value.productTypes,
        'selectedPromotionCode': value.selectedPromotionCode,
        'selectedPromotionValue': value.selectedPromotionValue,
        'code': value.code,
        'country': ApiCountryToJSON(value.country),
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
