/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiPromotionData,
    ApiPromotionDataFromJSON,
    ApiPromotionDataFromJSONTyped,
    ApiPromotionDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiPromotionDataResponse
 */
export interface ApiPromotionDataResponse {
    /**
     * 
     * @type {ApiPromotionData}
     * @memberof ApiPromotionDataResponse
     */
    item?: ApiPromotionData;
}

export function ApiPromotionDataResponseFromJSON(json: any): ApiPromotionDataResponse {
    return ApiPromotionDataResponseFromJSONTyped(json, false);
}

export function ApiPromotionDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPromotionDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': !exists(json, 'item') ? undefined : ApiPromotionDataFromJSON(json['item']),
    };
}

export function ApiPromotionDataResponseToJSON(value?: ApiPromotionDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': ApiPromotionDataToJSON(value.item),
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
