// TODO this file could go into lib/services
//  - but do we want the analytics package as a peerDep there?
//  - is this really ever used as it is?

import {
  checkoutFinishedEvent,
  checkoutStartedEvent,
  itemDetailEvent,
  itemExactListEvent,
  itemListEvent,
  routeChangeEvent,
  LoggerFactory,
  ItemListEvent,
  TrackingEvent
} from '@ibe/services';
import { tracking, trackingService } from './setup';
import { packageItemSelectEvent, productItemListEvent, productItemSelectEvent } from './events';
import { ApiBestPricePackageModel } from '@ibe/api';

const logger = LoggerFactory.get('TrackingSubscriptions');

const trackingSubscriptions = (): void => {
  routeChangeEvent.subscribe(() => {
    logger.log('routeChangeEvent');
    tracking.page();
  });

  itemListEvent.subscribe(payload => {
    logger.log('packageItemListEvent', payload);
    trackingService.trackPackageListView(
      payload.items as ApiBestPricePackageModel[],
      'view_item_list'
    );
  });

  productItemListEvent.subscribe(payload => {
    logger.log('productItemListEvent', payload);
    trackingService.trackProductListView(payload, 'view_item_list');
  });

  productItemSelectEvent.subscribe(payload => {
    logger.log('productItemSelectEvent', payload);
    trackingService.trackProductItem(payload.item, 'select_item');
  });

  packageItemSelectEvent.subscribe(payload => {
    logger.log('packageItemSelectEvent', payload);
    trackingService.trackItemSelect(payload.item, 'select_item', payload.index);
  });

  itemDetailEvent.subscribe(payload => {
    logger.log('itemDetailEvent', payload);
    trackingService.trackItem(payload.item, 'view_item');
  });

  checkoutStartedEvent.subscribe(payload => {
    logger.log('beginCheckoutEvent', payload);
    trackingService.trackCheckOutProcess(payload.booking, 'checkout');
  });

  checkoutFinishedEvent.subscribe(payload => {
    logger.log('purchaseEvent', payload);
    trackingService.trackCheckOutProcess(payload.booking, 'purchase');
  });
};
export default trackingSubscriptions;
