/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiContinent
 */
export interface ApiContinent {
    /**
     * 
     * @type {string}
     * @memberof ApiContinent
     */
    name: string;
}

export function ApiContinentFromJSON(json: any): ApiContinent {
    return ApiContinentFromJSONTyped(json, false);
}

export function ApiContinentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiContinent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function ApiContinentToJSON(value?: ApiContinent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
