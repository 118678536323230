/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPromotionData
 */
export interface ApiPromotionData {
    /**
     * 
     * @type {number}
     * @memberof ApiPromotionData
     */
    percentage?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPromotionData
     */
    percentagePromotionCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPromotionData
     */
    absoluteDiscount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPromotionData
     */
    absolutePromotionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPromotionData
     */
    rkid?: string;
}

export function ApiPromotionDataFromJSON(json: any): ApiPromotionData {
    return ApiPromotionDataFromJSONTyped(json, false);
}

export function ApiPromotionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPromotionData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'percentagePromotionCode': !exists(json, 'percentagePromotionCode') ? undefined : json['percentagePromotionCode'],
        'absoluteDiscount': !exists(json, 'absoluteDiscount') ? undefined : json['absoluteDiscount'],
        'absolutePromotionCode': !exists(json, 'absolutePromotionCode') ? undefined : json['absolutePromotionCode'],
        'rkid': !exists(json, 'rkid') ? undefined : json['rkid'],
    };
}

export function ApiPromotionDataToJSON(value?: ApiPromotionData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'percentage': value.percentage,
        'percentagePromotionCode': value.percentagePromotionCode,
        'absoluteDiscount': value.absoluteDiscount,
        'absolutePromotionCode': value.absolutePromotionCode,
        'rkid': value.rkid,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
