export enum Language {
  DE = 'de',
  DE_DE = 'de-DE'
}
export enum Currency {
  EUR = 'EUR'
}

export enum ConfigUrlParams {
  LANGUAGE = 'lng',
  CURRENCY = 'cur'
}

export const languages = Object.values(Language).sort((a, b) => a.localeCompare(b));
export const currency = Object.values(Currency).sort((a, b) => a.localeCompare(b));
