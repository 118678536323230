import {
  appService,
  ExternalSearchParamsContextProps,
  initAuth,
  initBookingService
} from '@ibe/components';
import { ConfigModel, ConfigService, defaultConfig, fetchJSON, LoggerFactory } from '@ibe/services';
import { ApiGender } from '@ibe/api';

import dayjs from 'dayjs';
import de from 'date-fns/locale/de';

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Currency, Language } from '../Translations';
import { enableTracking } from '../Tracking/setup';
import trackingSubscriptions from '../Tracking/trackingSubscriptions';
import getExternalConfig from './externalConfigs';
import { ThgConfigModel } from './ThgConfigModel';
import { createContext } from 'react';
import i18next from 'i18next';
import { clearStorage, packageIdSessionStorage } from '../Pages/Util/utils';

const logger = LoggerFactory.get('config');

export const productSearchSessionKey = 'thg-product-search-response';
export const extendedBookingSessionKey = 'thg-extended-booking-data';
export const summaryOverviewSessionKey = 'thg-summary-overview-data';
export const productSearchSessionTempKey = 'thg-product-search-response-temp';

const customConfig: Partial<ConfigModel> = {
  defaultLanguage: 'de',
  defaultCurrency: '',
  apiUrl: '/api',
  configUrl: '/config.json',
  displayFormatDate: {
    de_DE: 'DD.MM.YYYY',
    'de-DE': 'DD.MM.YYYY',
    de: 'DD.MM.YYYY'
  },
  displayFormatDateTime: {
    de_DE: 'DD.MM.YYYY HH:mm',
    'de-DE': 'DD.MM.YYYY HH:mm',
    de: 'DD.MM.YYYY HH:mm'
  },
  googleTagManagerContainerId: 'GTM-N6HPL4S',
  traveler: {
    genderValues: [ApiGender.MALE, ApiGender.FEMALE],
    maxChildAge: 17,
    maxInfantAge: 1,
    mockAvailable: false
  },
  session: {
    expirationTimeNotificationOffset: 5
  },
  sessionKeySearchParams: 'iso__best-price-availability-detail-url',
  outsideElementsContainerId: 'iso',
  useIndexedGeoUnits: true
};

defaultConfig.traveler.genderValues = []; // workaround cause "deepmerge" doesnt replace values in array

// setting both durations in customConfig would be better, but at the moment the merge afterwards would be incorrect
const durationOptions = {
  WEEKS: [1, 2, 3, 4],
  NIGHTS: [...Array.from({ length: 28 }, (_, i) => i + 1).filter(i => i > 2 && (i < 22 || i > 27))]
};
defaultConfig.exactSearchDurationOptions = durationOptions;
defaultConfig.packageSearchDurationOptions = durationOptions;

export const configService = new ConfigService<ConfigModel>(ConfigModel, defaultConfig);
configService.mergeIntoConfig(customConfig);

export const extendedConfigContext = createContext<ThgConfigModel>({});

function initTracking(config: ConfigModel): void {
  logger.log('tracking initialized...');
  enableTracking(config);
}

function initSubscriptions() {
  trackingSubscriptions();
}

function initLanguage(): void {
  registerLocale('de', de);
  appService.setLang(Language.DE);
  // now set dayjs and datepicker without region
  const langOnly = appService.lang.split('-')[0];
  dayjs.locale(langOnly);
  setDefaultLocale(langOnly);
  i18next.changeLanguage(langOnly);
}

function initCurrency(): void {
  appService.setCurrency(Currency.EUR);
}

export const boot = async (
  mode?: ExternalSearchParamsContextProps['mode'],
  isWhiteLabel?: boolean,
  keyPrefix?: string,
  configOverrides?: Partial<ConfigModel>,
  skipInitBooking?: boolean,
  isProductIBEWidget?: boolean,
  isCheckout?: boolean,
  thgConfig?: ThgConfigModel
): Promise<boolean> => {
  if (configOverrides) {
    configService.mergeIntoConfig(configOverrides);
  }
  const url = configService.get().configUrl;
  let externalConfig = {};
  if (!!mode) {
    externalConfig = getExternalConfig(mode, externalConfig);
  } else if (url) {
    externalConfig = (await fetchJSON(url)) as Partial<ConfigModel>;
  }
  configService.mergeIntoConfig(externalConfig);
  if (thgConfig) {
    configService.mergeIntoConfig(thgConfig);
  }
  const config = configService.get();
  logger.log('booting...');
  await appService.hydrate(config);
  await initAuth(config);

  initLanguage();
  initCurrency();

  if (!isWhiteLabel) {
    initTracking(config);
  }
  initSubscriptions();

  if (!isProductIBEWidget && !isCheckout) {
    clearStorage();
  }

  let bookingError = false;
  if (!skipInitBooking) {
    const packageId = packageIdSessionStorage.get()?.packageId;
    try {
      await initBookingService(
        config,
        appService.api,
        true,
        !!keyPrefix ? keyPrefix : undefined,
        packageId,
        true
      );
    } catch (err) {
      console.error(err);
      bookingError = true;
    }
  }

  logger.log('App hydrated', appService.currency, appService.lang);
  return bookingError;
};
