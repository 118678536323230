/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiImage
 */
export interface ApiImage {
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    assetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    caption?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiImage
     */
    srcSet: { [key: string]: string; };
}

export function ApiImageFromJSON(json: any): ApiImage {
    return ApiImageFromJSONTyped(json, false);
}

export function ApiImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetId': !exists(json, 'assetId') ? undefined : json['assetId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
        'srcSet': json['srcSet'],
    };
}

export function ApiImageToJSON(value?: ApiImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetId': value.assetId,
        'title': value.title,
        'caption': value.caption,
        'srcSet': value.srcSet,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
