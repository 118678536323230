/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiIBEProductResponse
 */
export interface ApiIBEProductResponse {
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    aktion: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiIBEProductResponse
     */
    aktiv: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    priceLimitationInfo: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiIBEProductResponse
     */
    region: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiIBEProductResponse
     */
    reiseArten: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    reisenWochentipp: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    titel: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    untertitel: string;
    /**
     * 
     * @type {string}
     * @memberof ApiIBEProductResponse
     */
    url: string;
}

export function ApiIBEProductResponseFromJSON(json: any): ApiIBEProductResponse {
    return ApiIBEProductResponseFromJSONTyped(json, false);
}

export function ApiIBEProductResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiIBEProductResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aktion': json['aktion'],
        'aktiv': json['aktiv'],
        'price': json['price'],
        'priceLimitationInfo': json['priceLimitationInfo'],
        'region': json['region'],
        'reiseArten': json['reiseArten'],
        'reisenWochentipp': json['reisenWochentipp'],
        'titel': json['titel'],
        'untertitel': json['untertitel'],
        'url': json['url'],
    };
}

export function ApiIBEProductResponseToJSON(value?: ApiIBEProductResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aktion': value.aktion,
        'aktiv': value.aktiv,
        'price': value.price,
        'priceLimitationInfo': value.priceLimitationInfo,
        'region': value.region,
        'reiseArten': value.reiseArten,
        'reisenWochentipp': value.reisenWochentipp,
        'titel': value.titel,
        'untertitel': value.untertitel,
        'url': value.url,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
