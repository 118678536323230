/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiNewsletterSubscriptionResponse
 */
export interface ApiNewsletterSubscriptionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiNewsletterSubscriptionResponse
     */
    success: boolean;
}

export function ApiNewsletterSubscriptionResponseFromJSON(json: any): ApiNewsletterSubscriptionResponse {
    return ApiNewsletterSubscriptionResponseFromJSONTyped(json, false);
}

export function ApiNewsletterSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNewsletterSubscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function ApiNewsletterSubscriptionResponseToJSON(value?: ApiNewsletterSubscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
