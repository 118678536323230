import * as ReactHabitat from 'react-habitat';
import SearchWidget from '../widgets/SearchWidget';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const domContainer = document.querySelector('[data-component="ThgSearch"]');
    if (!!domContainer) {
      const builder = new ReactHabitat.ContainerBuilder();
      builder.register(SearchWidget).as('ThgSearch');
      this.setContainer(builder.build());
    }
  }
}

export default new Habitat();
