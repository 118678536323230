/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiContinent,
    ApiContinentFromJSON,
    ApiContinentFromJSONTyped,
    ApiContinentToJSON,
    ApiCountryEyecatcher,
    ApiCountryEyecatcherFromJSON,
    ApiCountryEyecatcherFromJSONTyped,
    ApiCountryEyecatcherToJSON,
    ApiCountryInformation,
    ApiCountryInformationFromJSON,
    ApiCountryInformationFromJSONTyped,
    ApiCountryInformationToJSON,
    ApiFaq,
    ApiFaqFromJSON,
    ApiFaqFromJSONTyped,
    ApiFaqToJSON,
    ApiImage,
    ApiImageFromJSON,
    ApiImageFromJSONTyped,
    ApiImageToJSON,
    ApiMetaInformation,
    ApiMetaInformationFromJSON,
    ApiMetaInformationFromJSONTyped,
    ApiMetaInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCountry
 */
export interface ApiCountry {
    /**
     * 
     * @type {string}
     * @memberof ApiCountry
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCountry
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCountry
     */
    id?: string;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiCountry
     */
    mainImage: ApiImage;
    /**
     * 
     * @type {ApiCountryEyecatcher}
     * @memberof ApiCountry
     */
    eyecatcher?: ApiCountryEyecatcher;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiCountry
     */
    teaserImage: ApiImage;
    /**
     * 
     * @type {string}
     * @memberof ApiCountry
     */
    countryDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiCountry
     */
    topTravelDestination?: boolean;
    /**
     * 
     * @type {ApiContinent}
     * @memberof ApiCountry
     */
    continent: ApiContinent;
    /**
     * 
     * @type {number}
     * @memberof ApiCountry
     */
    teaserCounter?: number;
    /**
     * 
     * @type {Array<ApiCountryInformation>}
     * @memberof ApiCountry
     */
    countryInformation?: Array<ApiCountryInformation>;
    /**
     * 
     * @type {Array<ApiFaq>}
     * @memberof ApiCountry
     */
    faq?: Array<ApiFaq>;
    /**
     * 
     * @type {ApiMetaInformation}
     * @memberof ApiCountry
     */
    metaInformation?: ApiMetaInformation;
}

export function ApiCountryFromJSON(json: any): ApiCountry {
    return ApiCountryFromJSONTyped(json, false);
}

export function ApiCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mainImage': ApiImageFromJSON(json['mainImage']),
        'eyecatcher': !exists(json, 'eyecatcher') ? undefined : ApiCountryEyecatcherFromJSON(json['eyecatcher']),
        'teaserImage': ApiImageFromJSON(json['teaserImage']),
        'countryDescription': json['countryDescription'],
        'topTravelDestination': !exists(json, 'topTravelDestination') ? undefined : json['topTravelDestination'],
        'continent': ApiContinentFromJSON(json['continent']),
        'teaserCounter': !exists(json, 'teaserCounter') ? undefined : json['teaserCounter'],
        'countryInformation': !exists(json, 'countryInformation') ? undefined : ((json['countryInformation'] as Array<any>).map(ApiCountryInformationFromJSON)),
        'faq': !exists(json, 'faq') ? undefined : ((json['faq'] as Array<any>).map(ApiFaqFromJSON)),
        'metaInformation': !exists(json, 'metaInformation') ? undefined : ApiMetaInformationFromJSON(json['metaInformation']),
    };
}

export function ApiCountryToJSON(value?: ApiCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'code': value.code,
        'id': value.id,
        'mainImage': ApiImageToJSON(value.mainImage),
        'eyecatcher': ApiCountryEyecatcherToJSON(value.eyecatcher),
        'teaserImage': ApiImageToJSON(value.teaserImage),
        'countryDescription': value.countryDescription,
        'topTravelDestination': value.topTravelDestination,
        'continent': ApiContinentToJSON(value.continent),
        'teaserCounter': value.teaserCounter,
        'countryInformation': value.countryInformation === undefined ? undefined : ((value.countryInformation as Array<any>).map(ApiCountryInformationToJSON)),
        'faq': value.faq === undefined ? undefined : ((value.faq as Array<any>).map(ApiFaqToJSON)),
        'metaInformation': ApiMetaInformationToJSON(value.metaInformation),
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
