/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiImagePosition {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    BOTTOMLEFT = 'BOTTOM_LEFT',
    BOTTOMRIGHT = 'BOTTOM_RIGHT',
    TOPLEFT = 'TOP_LEFT',
    TOPRIGHT = 'TOP_RIGHT',
    NONE = 'NONE'
}

export function ApiImagePositionFromJSON(json: any): ApiImagePosition {
    return ApiImagePositionFromJSONTyped(json, false);
}

export function ApiImagePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiImagePosition {
    return json as ApiImagePosition;
}

export function ApiImagePositionToJSON(value?: ApiImagePosition | null): any {
    return value as any;
}

