import React, { Suspense, useState } from 'react';
import { MemoryRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { ConfigModel } from '@ibe/services';
import { useMount } from 'react-use';
import { observer } from 'mobx-react';
import 'dayjs/locale/de';
import { ExternalSearchParams, ExternalSearchParamsContextProps, Overlay } from '@ibe/components';
import theme from '../Theme/theme';
import { boot, extendedConfigContext } from '../Config/config';
import initI18next from '../Translations/setup';
import Search from '../Components/Search';
import { ThgConfigModel } from '../Config/ThgConfigModel';
import '../Theme/Styles/widgets.scss';

const SearchWidget = observer(
  (
    props: ExternalSearchParamsContextProps & {
      redirectUrl?: string;
      packageRedirectUrl?: string;
      thgConfig: ThgConfigModel;
      configOverrides?: ConfigModel;
    }
  ) => {
    const {
      mode,
      widgetSessionKeyPrefix: keyPrefix,
      redirectUrl,
      packageRedirectUrl,
      configOverrides,
      thgConfig
    } = props;
    const [bootstrappingFinished, setBootstrappingFinished] = useState<boolean>(false);

    const widgetSessionKeyPrefix = keyPrefix || document.body.id || '';

    useMount(async () => {
      await initI18next(mode, thgConfig.baseUrl, thgConfig.version);
      await boot(mode, false, widgetSessionKeyPrefix, configOverrides, true);
      setBootstrappingFinished(true);
    });

    return bootstrappingFinished ? (
      <ThemeProvider theme={theme}>
        <div style={{ backgroundColor: 'transparent' }} id="iso">
          <Suspense fallback={<Overlay fullOpacity />}>
            <extendedConfigContext.Provider value={thgConfig}>
              <MemoryRouter>
                <ExternalSearchParams {...props} widgetSessionKeyPrefix={widgetSessionKeyPrefix}>
                  <Search
                    redirectUrl={redirectUrl}
                    packageRedirectUrl={packageRedirectUrl}
                    maintenanceMode={thgConfig.maintenanceMode}
                  />
                </ExternalSearchParams>
              </MemoryRouter>
            </extendedConfigContext.Provider>
          </Suspense>
        </div>
      </ThemeProvider>
    ) : (
      <div id="iso" style={{ height: '300px' }}>
        <Overlay />
      </div>
    );
  }
);

export default SearchWidget;
