/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiNewsletterSubscriptionRequest
 */
export interface ApiNewsletterSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    salutation?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    signUpSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNewsletterSubscriptionRequest
     */
    externalCustomerId?: string;
}

export function ApiNewsletterSubscriptionRequestFromJSON(json: any): ApiNewsletterSubscriptionRequest {
    return ApiNewsletterSubscriptionRequestFromJSONTyped(json, false);
}

export function ApiNewsletterSubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNewsletterSubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'emailAddress': json['emailAddress'],
        'salutation': !exists(json, 'salutation') ? undefined : json['salutation'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'signUpSource': !exists(json, 'signUpSource') ? undefined : json['signUpSource'],
        'externalCustomerId': !exists(json, 'externalCustomerId') ? undefined : json['externalCustomerId'],
    };
}

export function ApiNewsletterSubscriptionRequestToJSON(value?: ApiNewsletterSubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'emailAddress': value.emailAddress,
        'salutation': value.salutation,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'signUpSource': value.signUpSource,
        'externalCustomerId': value.externalCustomerId,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
