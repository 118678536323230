/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiImage,
    ApiImageFromJSON,
    ApiImageFromJSONTyped,
    ApiImageToJSON,
    ApiImagePosition,
    ApiImagePositionFromJSON,
    ApiImagePositionFromJSONTyped,
    ApiImagePositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCountryInformation
 */
export interface ApiCountryInformation {
    /**
     * 
     * @type {string}
     * @memberof ApiCountryInformation
     */
    headline?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCountryInformation
     */
    subHeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCountryInformation
     */
    countryInformationDescription: string;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiCountryInformation
     */
    image?: ApiImage;
    /**
     * 
     * @type {ApiImagePosition}
     * @memberof ApiCountryInformation
     */
    imagePosition?: ApiImagePosition;
}

export function ApiCountryInformationFromJSON(json: any): ApiCountryInformation {
    return ApiCountryInformationFromJSONTyped(json, false);
}

export function ApiCountryInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCountryInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'subHeadline': !exists(json, 'subHeadline') ? undefined : json['subHeadline'],
        'countryInformationDescription': json['countryInformationDescription'],
        'image': !exists(json, 'image') ? undefined : ApiImageFromJSON(json['image']),
        'imagePosition': !exists(json, 'imagePosition') ? undefined : ApiImagePositionFromJSON(json['imagePosition']),
    };
}

export function ApiCountryInformationToJSON(value?: ApiCountryInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headline': value.headline,
        'subHeadline': value.subHeadline,
        'countryInformationDescription': value.countryInformationDescription,
        'image': ApiImageToJSON(value.image),
        'imagePosition': ApiImagePositionToJSON(value.imagePosition),
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
