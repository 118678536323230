import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend';
import { ExternalSearchParamsContextProps } from '@ibe/components';
import { ConfigModel } from '@ibe/services';
import { Language } from './index';
import getExternalConfig from '../Config/externalConfigs';

const order = ['querystring', 'localStorage', 'navigator'];

const initI18next = async (
  mode?: ExternalSearchParamsContextProps['mode'],
  baseUrl?: string,
  version?: string
): Promise<void> => {
  let externalConfig: Partial<ConfigModel> = {};
  if (!!mode) {
    externalConfig = getExternalConfig(mode, externalConfig);
  }
  await i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
      lng: Language.DE,
      fallbackLng: ['de'],
      fallbackNS: false,
      ns: [],
      debug: false,
      cleanCode: true,
      saveMissing: false,
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      react: {
        useSuspense: true
      },
      backend: {
        backends: [XHR],
        allowMultiLoading: true,
        backendOptions: [
          {
            loadPath: `${
              baseUrl ? baseUrl : externalConfig ? externalConfig.translationsUrl || '' : ''
            }/translations/generated/{{ns}}.{{lng}}.json`,
            queryStringParams: { v: `${version ? version : '1.0.0'}` }
          }
        ]
      },
      detection: {
        order
      }
    });
};

export default initI18next;
