import { LoggerFactory } from '@ibe/services';
import { ApiProductCard } from '../../api';
import ProductItemListEvent from './productItemListEvent';
import {
  ApiBestPricePackageModel,
  ApiBooking,
  ApiFlightItem,
  ApiItem,
  ApiItemType,
  ApiPriceModifierType
} from '@ibe/api';
import { PackageModelId } from '@ibe/components';
import dayjs from 'dayjs';

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

class TrackingService {
  private readonly logger = LoggerFactory.get('TrackingService.ts');

  private readonly itemBrandName = 'trendtours';

  private readonly affiliationName = this.itemBrandName + ' Online Store';

  private readonly searchResultPageName = 'Suchergebnisseite';

  private readonly daysStr = ' Tage';

  private readonly travelYearStr = 'Reisejahr ';

  trackProductListView(payload: ProductItemListEvent, eventName: string): void {
    const items = this.mapProductsToItems(payload.items);
    const data: Ecommerce = {
      item_list_id: this.searchResultPageName.toLowerCase(),
      item_list_name: this.searchResultPageName,
      items
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackPackageListView(payload: ApiBestPricePackageModel[], eventName: string): void {
    const items: Item[] = [];
    payload.forEach((it, index) => {
      const item: Item = this.mapItem(it, index);
      item.item_list_id = this.searchResultPageName.toLowerCase();
      item.item_list_name = this.searchResultPageName;
      items.push(item);
    });
    const data: Ecommerce = {
      item_list_id: this.searchResultPageName.toLowerCase(),
      item_list_name: this.searchResultPageName,
      items
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackItemSelect(
    payload: ApiBestPricePackageModel,
    eventName: string,
    selectedItemIndex?: number
  ): void {
    const itemListId = this.searchResultPageName.toLowerCase() + '_badeurlaub';
    const itemListName = this.searchResultPageName + ' Badeurlaub';
    const item = this.mapItem(payload, selectedItemIndex);
    item.item_list_id = itemListId;
    item.item_list_name = itemListName;

    const data: Ecommerce = {
      item_list_id: itemListId,
      item_list_name: itemListName,
      items: [item]
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackItem(payload: ApiItem, eventName: string): void {
    const item = this.mapItem(payload);
    const data: Ecommerce = {
      currency: payload.price.currencyCode,
      value: payload.price.finalPrice,
      items: [item]
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackProductItem(payload: ApiProductCard, eventName: string): void {
    const item = this.mapProductsToItems([payload]);
    const data: Ecommerce = {
      currency: payload.price.currencyCode,
      value: payload.price.amount,
      items: item
    };
    this.pushToWindowDataLayer(eventName, data);
  }

  trackCheckOutProcess(payload: ApiBooking | null, eventName: string): void {
    if (payload?.bookedItems) {
      const data = this.mapBookedItemsToItems(payload, eventName);
      this.pushToWindowDataLayer(eventName, data);
    }
  }

  private getItemCategory(typeCode?: string): string {
    switch (typeCode) {
      case 'SB':
        return 'Sonnenhits';
      case 'PFLUG':
        return 'Flugreise';
      default:
        return '';
    }
  }

  private mapItem(packageItem: Partial<ApiBestPricePackageModel>, index = 0): Item {
    const code = packageItem.code;
    const name = packageItem.description ? packageItem.description : packageItem.name;

    // TODO: Check price & discount
    const priceModifiers = packageItem.price?.modifiers?.find(
      pr => pr.type === ApiPriceModifierType.DISCOUNT
    );
    let coupon = '';
    if (packageItem?.id) {
      const decodeId = JSON.parse(atob(packageItem.id));
      coupon = decodeId.promotionCode ? decodeId.promotionCode : '';
    }
    let discount = priceModifiers?.absolute;
    discount = discount ? Math.abs(discount) : 0;

    const itemCategory = this.getItemCategory(packageItem.typeCode);
    const itemCategory2 = packageItem?.geoAssignment?.geoUnit?.name;
    const endDate = dayjs(packageItem.endDate);
    const itemCategory3 = this.travelYearStr + endDate.year();

    // TODO: check if shown duration matches the selected package (e.g.: '15-Tägige Reise' --> 15)
    const duration = packageItem.duration?.duration + this.daysStr;
    const price = packageItem.price?.finalPrice;

    const item: Item = {
      item_id: code,
      item_name: name,
      affiliation: this.affiliationName,
      coupon: coupon,
      discount: discount,
      index: index,
      item_brand: this.itemBrandName,
      item_category: itemCategory,
      item_category2: itemCategory2,
      item_category3: itemCategory3,
      item_variant: duration,
      price: price,
      quantity: 1
    };
    return item;
  }

  private mapBookedItemsToItems(payload: ApiBooking, eventName: string): Ecommerce {
    const bookedItems = payload?.bookedItems;
    const items: Item[] = [];

    const packageItem = bookedItems.find(it => it.itemType === ApiItemType.PACKAGE);
    const decodedPackageId = packageItem?.id ? JSON.parse(atob(packageItem?.id)) : undefined;
    let decodedPackageModelId: Partial<PackageModelId>;
    let itemCategory = '';
    if (!!decodedPackageId) {
      decodedPackageModelId = JSON.parse(atob(decodedPackageId.packageModelId));
      const typeCode = decodedPackageModelId.typeCode;
      itemCategory = this.getItemCategory(typeCode);
    }

    const coupon = payload.promoCodes[0];
    const travelStartDate = dayjs(payload.travelStartDate);
    const travelEndDate = dayjs(payload.travelEndDate);

    // Find the destination name by using the destinationCode and flightItem, because it could be an open jaw flight
    let itemCategory2 = ''; // Currently destination is enough
    const bookedFlightItem = payload.bookedItems.find(it => it.itemType === ApiItemType.FLIGHT);
    if (bookedFlightItem?.idParent) {
      const decodedIdParent = JSON.parse(atob(bookedFlightItem.idParent));
      const decodedIdParentId = JSON.parse(atob(decodedIdParent.id));
      const destinationCode = decodedIdParentId.destinationCode;

      const outboundFlightItem = payload.items.find(it => {
        const flightItem = it as ApiFlightItem;
        return (
          flightItem.itemType === ApiItemType.FLIGHT &&
          flightItem.segment.find(se => se.destination.code === destinationCode)
        );
      });
      itemCategory2 = outboundFlightItem?.description || '';
    }

    const itemCategory3 = this.travelYearStr + travelEndDate.year();

    // Default mapping for same properties to be used for all other items
    const baseItem: Item = {
      affiliation: this.affiliationName,
      item_brand: this.itemBrandName,
      item_category: itemCategory,
      item_category2: itemCategory2,
      item_category3: itemCategory3
    };

    bookedItems.forEach((btItem, idx) => {
      const quantity = btItem.unitsCount;
      const name = btItem.description ? btItem.description : btItem.name;
      // Show price per quantity (rounded mean value)
      let price =
        btItem?.price?.finalPrice > 0 && quantity
          ? btItem?.price?.finalPrice / quantity
          : btItem?.price?.finalPrice;
      price = Math.round(price || 0);

      if (btItem.itemType === ApiItemType.PACKAGE) {
        const packageCode = decodedPackageModelId?.code;
        // TODO: check if shown duration matches the selected product (e.g.: '15-Tägige Reise' --> 15)
        const duration = travelEndDate.diff(travelStartDate, 'days') + this.daysStr;
        let discount = 0; //todo fix me
        discount = discount ? Math.abs(discount) : 0;
        const item: Item = {
          item_id: packageCode,
          item_name: name,
          ...baseItem,
          item_variant: duration,
          discount: discount,
          coupon: coupon,
          index: 0,
          price: price,
          quantity: quantity
        };
        items.push(item);
      } else {
        const decodedId = JSON.parse(atob(btItem.id));
        const index = decodedId?.identifier - 1;
        const decodedIdParent = JSON.parse(atob(btItem.idParent));
        const decodedIdParentId = JSON.parse(atob(decodedIdParent.id));
        const serviceCode = decodedIdParentId?.serviceCode || '';
        const item: Item = {
          item_id: serviceCode,
          item_name: name,
          ...baseItem,
          index: index,
          price: price,
          quantity: quantity
        };
        items.push(item);
      }
    });

    const currency = payload.price.currencyCode;
    const value = payload.price.finalPrice;
    const ecommerceData: Ecommerce = {
      value: value,
      currency: currency,
      coupon: coupon,
      items: items
    };

    // Set purchase event related properties
    if (eventName === 'purchase') {
      // TODO: Check if this is the right place for tax
      const tax = payload.price.modifiers.find(pr => pr.type === ApiPriceModifierType.TAX)
        ?.absolute;
      ecommerceData.transaction_id = +payload.bookingNumber;
      ecommerceData.shipping = 0; // No shipping whatsoever
      ecommerceData.tax = tax;
    }
    return ecommerceData;
  }

  private mapProductsToItems(payload: ApiProductCard[]): Array<Item> {
    return payload.map((card, index) => {
      const price =
        card.strikeThroughPrice && card.strikeThroughPrice.amount
          ? card.strikeThroughPrice
          : card.price;

      const name = this.replaceHtmlAndUmlauts(card.name);
      const seasonName = this.replaceHtmlAndUmlauts(card.seasonName);

      const item: Item = {
        item_id: card.code,
        item_name: name,
        affiliation: this.affiliationName,
        coupon: card.selectedPromotionCode || '',
        discount: card.selectedPromotionCode ? parseFloat(card.selectedPromotionValue) : '',
        index,
        item_brand: this.itemBrandName,
        item_category: card.productTypes.join(', '),
        item_category2: card.country.name,
        item_category3: seasonName || '',
        price: price.amount,
        quantity: 1,
        item_list_id: this.searchResultPageName.toLowerCase(),
        item_list_name: this.searchResultPageName
      };
      return item;
    });
  }

  private pushToWindowDataLayer(eventName: string, data: Ecommerce): void {
    // Clear data layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    // Push new values
    window.dataLayer.push({
      event: eventName,
      ecommerce: data
    });
  }

  private replaceHtmlAndUmlauts(input: string) {
    if (!input) {
      return input;
    }
    let output = this.clearHTMLTags(input);
    output = output.replaceAll('&auml;', 'ä');
    output = output.replaceAll('&Auml;', 'Ä');
    output = output.replaceAll('&ouml;', 'ö');
    output = output.replaceAll('&Ouml;', 'Ö');
    output = output.replaceAll('&uuml;', 'ü');
    output = output.replaceAll('&Uuml;', 'Ü');
    return output.trim();
  }

  private clearHTMLTags = (input: string) => {
    const output = new DOMParser().parseFromString(input, 'text/html');
    return output.body.textContent || '';
  };
}

export default TrackingService;
