/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiProductSearchRequest
 */
export interface ApiProductSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiProductSearchRequest
     */
    searchTerm: string;
}

export function ApiProductSearchRequestFromJSON(json: any): ApiProductSearchRequest {
    return ApiProductSearchRequestFromJSONTyped(json, false);
}

export function ApiProductSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerm': json['searchTerm'],
    };
}

export function ApiProductSearchRequestToJSON(value?: ApiProductSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchTerm': value.searchTerm,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
