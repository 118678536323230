/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiBrochureSubscriptionRequest
 */
export interface ApiBrochureSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    salutation?: ApiBrochureSubscriptionRequestSalutationEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    dateOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    gender: ApiBrochureSubscriptionRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    houseNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    mobilePhoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBrochureSubscriptionRequest
     */
    emailAddress?: string;
}

export function ApiBrochureSubscriptionRequestFromJSON(json: any): ApiBrochureSubscriptionRequest {
    return ApiBrochureSubscriptionRequestFromJSONTyped(json, false);
}

export function ApiBrochureSubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBrochureSubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'salutation': !exists(json, 'salutation') ? undefined : json['salutation'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth']),
        'gender': json['gender'],
        'country': json['country'],
        'postalCode': json['postalCode'],
        'city': json['city'],
        'street': json['street'],
        'houseNumber': json['houseNumber'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'mobilePhoneNumber': !exists(json, 'mobilePhoneNumber') ? undefined : json['mobilePhoneNumber'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
    };
}

export function ApiBrochureSubscriptionRequestToJSON(value?: ApiBrochureSubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'salutation': value.salutation,
        'title': value.title,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth),
        'gender': value.gender,
        'country': value.country,
        'postalCode': value.postalCode,
        'city': value.city,
        'street': value.street,
        'houseNumber': value.houseNumber,
        'phoneNumber': value.phoneNumber,
        'mobilePhoneNumber': value.mobilePhoneNumber,
        'emailAddress': value.emailAddress,
    };
}

/**
* @export
* @enum {string}
*/
export enum ApiBrochureSubscriptionRequestSalutationEnum {
    MR = 'MR',
    MRS = 'MRS'
}
/**
* @export
* @enum {string}
*/
export enum ApiBrochureSubscriptionRequestGenderEnum {
    FEMALE = 'FEMALE',
    MALE = 'MALE'
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
