/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiImage,
    ApiImageFromJSON,
    ApiImageFromJSONTyped,
    ApiImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiMetaInformation
 */
export interface ApiMetaInformation {
    /**
     * 
     * @type {string}
     * @memberof ApiMetaInformation
     */
    metaTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMetaInformation
     */
    metaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMetaInformation
     */
    displayUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMetaInformation
     */
    socialMediaTitle?: string;
    /**
     * 
     * @type {ApiImage}
     * @memberof ApiMetaInformation
     */
    socialMediaImage?: ApiImage;
    /**
     * 
     * @type {string}
     * @memberof ApiMetaInformation
     */
    socialMediaDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMetaInformation
     */
    robots?: Array<string>;
}

export function ApiMetaInformationFromJSON(json: any): ApiMetaInformation {
    return ApiMetaInformationFromJSONTyped(json, false);
}

export function ApiMetaInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiMetaInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metaTitle': !exists(json, 'metaTitle') ? undefined : json['metaTitle'],
        'metaDescription': !exists(json, 'metaDescription') ? undefined : json['metaDescription'],
        'displayUrl': !exists(json, 'displayUrl') ? undefined : json['displayUrl'],
        'socialMediaTitle': !exists(json, 'socialMediaTitle') ? undefined : json['socialMediaTitle'],
        'socialMediaImage': !exists(json, 'socialMediaImage') ? undefined : ApiImageFromJSON(json['socialMediaImage']),
        'socialMediaDescription': !exists(json, 'socialMediaDescription') ? undefined : json['socialMediaDescription'],
        'robots': !exists(json, 'robots') ? undefined : json['robots'],
    };
}

export function ApiMetaInformationToJSON(value?: ApiMetaInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metaTitle': value.metaTitle,
        'metaDescription': value.metaDescription,
        'displayUrl': value.displayUrl,
        'socialMediaTitle': value.socialMediaTitle,
        'socialMediaImage': ApiImageToJSON(value.socialMediaImage),
        'socialMediaDescription': value.socialMediaDescription,
        'robots': value.robots,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
