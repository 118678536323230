/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiProductCard,
    ApiProductCardFromJSON,
    ApiProductCardFromJSONTyped,
    ApiProductCardToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiProductSearchResponse
 */
export interface ApiProductSearchResponse {
    /**
     * 
     * @type {Array<ApiProductCard>}
     * @memberof ApiProductSearchResponse
     */
    items?: Array<ApiProductCard>;
}

export function ApiProductSearchResponseFromJSON(json: any): ApiProductSearchResponse {
    return ApiProductSearchResponseFromJSONTyped(json, false);
}

export function ApiProductSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(ApiProductCardFromJSON)),
    };
}

export function ApiProductSearchResponseToJSON(value?: ApiProductSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(ApiProductCardToJSON)),
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
