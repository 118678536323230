import { Api, Configuration } from '../../api';
import { useAppService, useAuth } from '@ibe/components';
import { removeTrailingSlashes } from '@ibe/services';
import useThgConfig from '../Hooks/useThgConfig';

const useSearchApi = (): Api => {
  const config = useThgConfig();
  const auth = useAuth();
  const app = useAppService();
  return new Api(
    new Configuration({
      basePath: removeTrailingSlashes(config.productApiUrl || ''),
      apiKey: auth.authState.token || undefined,
      headers: { 'Accept-Language': app.lang, Currency: app.currency }
    })
  );
};

export default useSearchApi;
