import { ExternalSearchParamsContextProps } from '@ibe/components';
import { ConfigModel } from '@ibe/services';

export const cont = {
  apiUrl: 'http://cont.ibe-thg.isoad.isogmbh.de/api',
  translationsUrl: 'http://cont.ibe-thg.isoad.isogmbh.de',
  appTitle: 'Continuous / THG IBE',
  stickySearchOffsetClass: '.navbar.navbar-default.tt__navbar',
  googleTagManagerContainerId: 'GTM-N6HPL4S',
  traveler: {
    mockAvailable: true
  }
};

export const isointtest = {
  apiUrl: 'https://ibe-thg-test.ops.local:444/api',
  translationsUrl: 'https://ibe-thg-test.ops.local:444',
  appTitle: 'Continuous / THG IBE',
  stickySearchOffsetClass: '.navbar.navbar-default.tt__navbar',
  googleTagManagerContainerId: 'GTM-N6HPL4S',
  traveler: {
    mockAvailable: true
  }
};

export const test = {
  apiUrl: 'https://ibe-test.trendtours.de/api',
  translationsUrl: 'https://ibe-test.trendtours.de',
  appTitle: 'Continuous / THG IBE',
  googleTagManagerContainerId: 'GTM-N4S9MS',
  stickySearchOffsetClass: '.navbar.navbar-default.tt__navbar',
  traveler: {
    mockAvailable: false
  }
};

export const prod = {
  apiUrl: 'https://ibe.trendtours.de/api',
  translationsUrl: 'https://ibe.trendtours.de',
  appTitle: 'Continuous / THG IBE',
  googleTagManagerContainerId: 'GTM-N4S9MS',
  stickySearchOffsetClass: '.navbar.navbar-default.tt__navbar',
  traveler: {
    mockAvailable: false
  }
};

export const local = {
  googleApiKey: 'AIzaSyCPNvzE5XZQegZCY9H-ZePg-Vc4109vsPU',
  apiUrl: '/api/',
  stickySearchOffsetClass: '.navbar.navbar-default.tt__navbar',
  translationsUrls: '',
  traveler: {
    mockAvailable: true
  }
};

const getExternalConfig = (
  mode: ExternalSearchParamsContextProps['mode'],
  externalConfig: Partial<ConfigModel>
): Partial<ConfigModel> => {
  switch (mode) {
    case 'INTTEST':
      return { ...externalConfig, ...((isointtest as unknown) as Partial<ConfigModel>) };
    case 'INTPROD':
      return { ...externalConfig, ...((cont as unknown) as Partial<ConfigModel>) };
    case 'PROD':
      return { ...externalConfig, ...((prod as unknown) as Partial<ConfigModel>) };
    case 'TEST':
      return { ...externalConfig, ...((test as unknown) as Partial<ConfigModel>) };
    default:
      return { ...externalConfig, ...((local as unknown) as Partial<ConfigModel>) };
  }
};

export default getExternalConfig;
