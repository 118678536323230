import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import packageJson from '../../package.json';
import { ConfigModel } from '@ibe/services';
import TrackingService from './TrackingService';

const analyticsOptions: {
  app: string;
  version: string;
  debug: boolean;
  plugins: Array<Record<string, unknown>>;
} = {
  app: packageJson.name,
  version: packageJson.version,
  debug: process.env.NODE_ENV !== 'production',
  plugins: []
};

export let tracking = Analytics(analyticsOptions);
export const trackingService = new TrackingService();
export const enableTracking = (config: ConfigModel) => {
  if (config.googleTagManagerContainerId) {
    analyticsOptions.plugins.push(
      googleTagManager({
        containerId: config.googleTagManagerContainerId
      })
    );
  }

  tracking = Analytics(analyticsOptions);
};
