/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiBrochureSubscriptionRequest,
    ApiBrochureSubscriptionRequestFromJSON,
    ApiBrochureSubscriptionRequestFromJSONTyped,
    ApiBrochureSubscriptionResponse,
    ApiBrochureSubscriptionResponseFromJSON,
    ApiBrochureSubscriptionResponseFromJSONTyped,
    ApiIBEProductResponse,
    ApiIBEProductResponseFromJSON,
    ApiIBEProductResponseFromJSONTyped,
    ApiNewsletterSubscriptionRequest,
    ApiNewsletterSubscriptionRequestFromJSON,
    ApiNewsletterSubscriptionRequestFromJSONTyped,
    ApiNewsletterSubscriptionResponse,
    ApiNewsletterSubscriptionResponseFromJSON,
    ApiNewsletterSubscriptionResponseFromJSONTyped,
    ApiProductSearchRequest,
    ApiProductSearchRequestFromJSON,
    ApiProductSearchRequestFromJSONTyped,
    ApiProductSearchResponse,
    ApiProductSearchResponseFromJSON,
    ApiProductSearchResponseFromJSONTyped,
    ApiPromotionDataRequest,
    ApiPromotionDataRequestFromJSON,
    ApiPromotionDataRequestFromJSONTyped,
    ApiPromotionDataResponse,
    ApiPromotionDataResponseFromJSON,
    ApiPromotionDataResponseFromJSONTyped,
} from '../models';

export interface DefaultApiAddPromotionCodeRequest {
    apiPromotionDataRequest: ApiPromotionDataRequest;
}

export interface DefaultApiGetIBEProductRequest {
    productCode: string;
}

export interface DefaultApiSearchProductsRequest {
    apiProductSearchRequest: ApiProductSearchRequest;
}

export interface DefaultApiSubscribeRequest {
    apiNewsletterSubscriptionRequest: ApiNewsletterSubscriptionRequest;
}

export interface DefaultApiSubscribeToBrochureRequest {
    apiBrochureSubscriptionRequest: ApiBrochureSubscriptionRequest;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add a promotion code.
     */
    async addPromotionCodeRaw(requestParameters: DefaultApiAddPromotionCodeRequest): Promise<runtime.ApiResponse<ApiPromotionDataResponse>> {
        if (requestParameters.apiPromotionDataRequest === null || requestParameters.apiPromotionDataRequest === undefined) {
            throw new runtime.RequiredError('apiPromotionDataRequest','Required parameter requestParameters.apiPromotionDataRequest was null or undefined when calling addPromotionCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-IBE-Authorization"] = this.configuration.apiKey("X-IBE-Authorization"); // bearerAuth authentication
        }

        const response = await this.request({
            path: `/promotion`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPromotionDataRequestFromJSONTyped(requestParameters.apiPromotionDataRequest, false),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPromotionDataResponseFromJSON(jsonValue));
    }

    /**
     * Add a promotion code.
     */
    async addPromotionCode(apiPromotionDataRequest: ApiPromotionDataRequest): Promise<ApiPromotionDataResponse> {
        const response = await this.addPromotionCodeRaw({ apiPromotionDataRequest: apiPromotionDataRequest });
        return await response.value();
    }

    /**
     * Retrieve product data for ibe
     */
    async getIBEProductRaw(requestParameters: DefaultApiGetIBEProductRequest): Promise<runtime.ApiResponse<ApiIBEProductResponse>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling getIBEProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-IBE-Authorization"] = this.configuration.apiKey("X-IBE-Authorization"); // bearerAuth authentication
        }

        const response = await this.request({
            path: `/ibeContent/{productCode}`.replace(`{${"productCode"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiIBEProductResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve product data for ibe
     */
    async getIBEProduct(productCode: string): Promise<ApiIBEProductResponse> {
        const response = await this.getIBEProductRaw({ productCode: productCode });
        return await response.value();
    }

    /**
     * Search for products within cms
     */
    async searchProductsRaw(requestParameters: DefaultApiSearchProductsRequest): Promise<runtime.ApiResponse<ApiProductSearchResponse>> {
        if (requestParameters.apiProductSearchRequest === null || requestParameters.apiProductSearchRequest === undefined) {
            throw new runtime.RequiredError('apiProductSearchRequest','Required parameter requestParameters.apiProductSearchRequest was null or undefined when calling searchProducts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-IBE-Authorization"] = this.configuration.apiKey("X-IBE-Authorization"); // bearerAuth authentication
        }

        const response = await this.request({
            path: `/product/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiProductSearchRequestFromJSONTyped(requestParameters.apiProductSearchRequest, false),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductSearchResponseFromJSON(jsonValue));
    }

    /**
     * Search for products within cms
     */
    async searchProducts(apiProductSearchRequest: ApiProductSearchRequest): Promise<ApiProductSearchResponse> {
        const response = await this.searchProductsRaw({ apiProductSearchRequest: apiProductSearchRequest });
        return await response.value();
    }

    /**
     * Subscribe to a newsletter
     */
    async subscribeRaw(requestParameters: DefaultApiSubscribeRequest): Promise<runtime.ApiResponse<ApiNewsletterSubscriptionResponse>> {
        if (requestParameters.apiNewsletterSubscriptionRequest === null || requestParameters.apiNewsletterSubscriptionRequest === undefined) {
            throw new runtime.RequiredError('apiNewsletterSubscriptionRequest','Required parameter requestParameters.apiNewsletterSubscriptionRequest was null or undefined when calling subscribe.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-IBE-Authorization"] = this.configuration.apiKey("X-IBE-Authorization"); // bearerAuth authentication
        }

        const response = await this.request({
            path: `/newsletter/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiNewsletterSubscriptionRequestFromJSONTyped(requestParameters.apiNewsletterSubscriptionRequest, false),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiNewsletterSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Subscribe to a newsletter
     */
    async subscribe(apiNewsletterSubscriptionRequest: ApiNewsletterSubscriptionRequest): Promise<ApiNewsletterSubscriptionResponse> {
        const response = await this.subscribeRaw({ apiNewsletterSubscriptionRequest: apiNewsletterSubscriptionRequest });
        return await response.value();
    }

    /**
     * Subscribe to a printed brochure.
     */
    async subscribeToBrochureRaw(requestParameters: DefaultApiSubscribeToBrochureRequest): Promise<runtime.ApiResponse<ApiBrochureSubscriptionResponse>> {
        if (requestParameters.apiBrochureSubscriptionRequest === null || requestParameters.apiBrochureSubscriptionRequest === undefined) {
            throw new runtime.RequiredError('apiBrochureSubscriptionRequest','Required parameter requestParameters.apiBrochureSubscriptionRequest was null or undefined when calling subscribeToBrochure.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-IBE-Authorization"] = this.configuration.apiKey("X-IBE-Authorization"); // bearerAuth authentication
        }

        const response = await this.request({
            path: `/brochure/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiBrochureSubscriptionRequestFromJSONTyped(requestParameters.apiBrochureSubscriptionRequest, false),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiBrochureSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Subscribe to a printed brochure.
     */
    async subscribeToBrochure(apiBrochureSubscriptionRequest: ApiBrochureSubscriptionRequest): Promise<ApiBrochureSubscriptionResponse> {
        const response = await this.subscribeToBrochureRaw({ apiBrochureSubscriptionRequest: apiBrochureSubscriptionRequest });
        return await response.value();
    }

}

// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
